import ReactMarkdown from 'react-markdown';

export function Markdown({ children }: { children: string }) {
  return (
    <ReactMarkdown
      components={{
        h1: ({ children }) => (
          <h1 className="mb-2 text-2xl font-bold">{children}</h1>
        ),
        h2: ({ children }) => (
          <h2 className="mb-2 text-lg font-bold">{children}</h2>
        ),
        h3: ({ children }) => (
          <h3 className="mb-2 text-base font-bold">{children}</h3>
        ),
        strong: ({ children }) => (
          <strong className="font-bold">{children}</strong>
        ),
        hr: () => <hr className="mb-6 mt-4" />,
        p: ({ children }) => <p className="mb-2">{children}</p>,
        ul: ({ children }) => <ul className="mb-2 pl-6">{children}</ul>,
        li: ({ children }) => <li className="ml-0 list-disc">{children}</li>,
        a: ({ children, href }) => (
          <a
            href={href}
            className="text-muted-foreground underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {children}
          </a>
        ),
      }}
    >
      {children}
    </ReactMarkdown>
  );
}
